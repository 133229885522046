import React, { useEffect } from 'react'
import Header from './Header'
import { Outlet, useLocation } from 'react-router-dom'
import Loading from '../Loading'

const PathChangeListner = ()=>{
  const location = useLocation()

  useEffect(()=>{
      document.querySelector('#meta-url').setAttribute('content', window.location.pathname)
  }, [location.path])

}

const Container = () => {
  return (
    <>
        <Loading  id='loading__div' style={{display: 'none', position: 'fixed', width: '100%'}}/>
        <PathChangeListner />
        <Header/>
        <div style={{flexGrow: 1}}>
          <Outlet/>
        </div>
          
    </>
  )
}

export default Container